import AuthGuard from '@comp/AuthGuard';
import BaseLayout from '@comp/board/BaseLayout';
import GuestGuard from '@root/components/GuestGuard';
import Loadable from '@root/routes/Loadable';
import { accountRoute } from '@root/routes/accountRoute';
import { authenticationRoute } from '@root/routes/authenticationRoute';
import { banksRoute } from '@root/routes/banksRoute';
import { binRoute } from '@root/routes/binRoute';
import { boardRoute } from '@root/routes/boardRoute';
import { cascadingRoute } from '@root/routes/cascadingRoute';
import { city24Route } from '@root/routes/city24Route';
import { codesRoute } from '@root/routes/codesRoute';
import { error401Route } from '@root/routes/error401Route';
import { error404Route } from '@root/routes/error404Route';
import { error500Route } from '@root/routes/error500Route';
import { exportRoute } from '@root/routes/exportRoute';
import { finMonRoute } from '@root/routes/finMonRoute';
import { flowsRoute } from '@root/routes/flowsRoute';
import { gatewaysRoute } from '@root/routes/gatewaysRoute';
import { merchantsRoute } from '@root/routes/merchantsRoute';
import { notFoundRoute } from '@root/routes/notFoundRoute';
import { reconciliationRoute } from '@root/routes/reconciliationRoute';
import { rolesRoute } from '@root/routes/rolesRoute';
import { terminalsRoute } from '@root/routes/terminalsRoute';
import { transactionsRoute } from '@root/routes/transactionsRoute';
import { usersRoute } from '@root/routes/usersRoute';
import { lazy } from 'react';
import { companyRoute } from './companyRoute';
import { registerPaymentsRoute } from './registerPaymentsRoute';

const Login = Loadable(lazy(() => import('@pages/authentication/Login')));

const routes = [
  authenticationRoute,
  {
    path: '*',
    element: (
      <AuthGuard>
        <BaseLayout />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      boardRoute,
      usersRoute,
      rolesRoute,
      codesRoute,
      city24Route,
      banksRoute,
      flowsRoute,
      gatewaysRoute,
      terminalsRoute,
      cascadingRoute,
      merchantsRoute,
      companyRoute,
      binRoute,
      transactionsRoute,
      finMonRoute,
      exportRoute,
      reconciliationRoute,
      error401Route,
      error404Route,
      error500Route,
      notFoundRoute,
      accountRoute,
      registerPaymentsRoute
    ]
  }
];

export default routes;
