import { CssBaseline, LinearProgress, ThemeProvider } from '@mui/material';
import { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { useRoutes } from 'react-router-dom';
import { InactivityModal } from './components/InactivityModal/InactivityModal';
import RTL from './components/RTL';
import SplashScreen from './components/SplashScreen';
import Timer from './components/Timer/Timer';
import { gtmConfig } from './config';
import useActivity from './hooks/useActivity';
import useAuth from './hooks/useAuth';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
import './i18n';
import { setupInterceptors } from './lib/axios';
import gtm from './lib/gtm';
import routes from './routes';
import { createCustomTheme } from './theme';

function App() {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const { isModalOpen, handleModalClose, countdown } = useActivity(900000);
  useEffect(() => {
    setupInterceptors(setLoading);
  }, []);

  useEffect(() => {
    gtm.initialize(gtmConfig);
  }, []);

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme
  });

  useScrollReset();

  return (
    <ThemeProvider theme={theme}>
      <RTL direction={settings.direction}>
        <CssBaseline />
        <Toaster position="top-center" />
        {loading && (
          <LinearProgress
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              zIndex: 10000
            }}
          />
        )}
        {auth.isInitialized ? content : <SplashScreen />}
        <InactivityModal isOpen={isModalOpen} onClose={handleModalClose} />
        <Timer countdown={countdown} />
      </RTL>
    </ThemeProvider>
  );
}

export default App;
