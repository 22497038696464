import { useTranslation } from 'react-i18next';

const Timer = ({ countdown }) => {
  const { t } = useTranslation();
  const token = localStorage.getItem('accessToken');
  const formatTime = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}m ${seconds}s`;
  };

  if (countdown > 600000) return null;

  if (!token) {
    return null;
  }

  return (
    <div
      style={{
        position: 'fixed',
        bottom: '10px',
        right: '30px',
        padding: '10px',
        backgroundColor: '#000',
        color: '#fff',
        borderRadius: '5px'
      }}
    >
      {t('autoLogout')} {formatTime(countdown)}
    </div>
  );
};

export default Timer;
