const buttons = {
  'Recovery button': 'Відновити',
  'Change button': 'Змінити',
  'Back button': 'Назад',
  'Create button': 'Створити',
  'Save button': 'Зберегти',
  'Update button': 'Оновити',
  'Delete button': 'Видалити',
  'Search button': 'Знайти',
  'Clear button': 'Очистити',
  'Copy button': 'Копіювати',
  'Cancel button': 'Скасування',
  'Apply button': 'Підтвердити',
  'Ok button': 'Ok',
  'Back to Home': 'Повернутися на головну',
  'status-city': 'Инфо',
  'cancel-city': 'Скасування',
  'register-city': 'Зареєструвати',
  cancel_overdraft: 'Скасувати овердрафт',
  receipt: 'Квитанція',
  Reference: 'Довідка',
  clear_filters: 'Скинути фільтри',
  'Extra Data': 'Додаткова інформація'
};

const menu = {
  'Transaction menu': 'Транзакції',
  FinMon: 'Рішення ФМ',
  FinMonUpdate: 'Оновлення Фін Моніторинг',
  'Users menu': 'Користувачі',
  'Role menu': 'Ролі',
  'User Item': 'Інформація про користувача',
  'Banks menu': 'Банки',
  'Flow menu': 'Схеми',
  'Gateway menu': 'Шлюз',
  'Cascading menu': 'Каскад',
  'Cascading rules menu': 'Правила',
  'Cascading models menu': 'Моделі',
  'Terminals menu': 'Термінали',
  'Merchant menu': 'Мерчанти',
  'Description menu': 'Експорт документів',
  Reports: 'Звіти',
  'Description menu custom': 'Експорт',
  'Description menu city24': 'Экспорт city24',
  'Codes menu': 'Коди',
  'Bin menu': 'Bin',
  'Reconciliation menu': 'Звірка'
};

const text = {
  userId: 'ID користувача',
  Download_list_of_terrorists: 'Завантажити список терористів',
  'Having an account': 'У мене вже є обліковий запис',
  'Reset account password': 'Відновити доступ',
  'Success update': 'Запись обновлена',
  'Success upload': 'Файл загружен',
  'Do you want to remove': 'Бажаєте видалити запис?',
  'Register account': 'Реєстрація',
  'Success registration': 'Ви успішно зареєстровані',
  'Success deleted': 'Запис видалено',
  'Reset title': 'Відновлення паролю',
  'Success recovery send token': 'Інструкцію надіслано на email',
  decreaseAmount: 'Сума зменьшення',
  'Overdraft Date': 'Зняти в',

  'Transactions List': 'Список транзакцій',
  'Transactions Item': 'Інформація про транзакцію',
  letter_pumb: 'Лист ПУМБ',
  letter_c2a: 'Лист C2A',
  startActionDate: 'Встановлення ділових відносин',

  'Users List': 'Список користувачів',
  'User Item Update': 'Оновлення користувача',
  'User Item Create': 'Створення користувача',
  'Active user':
    'Ви були неактивні більше 15 хв. Сеанс роботи було автоматично завершено. Авторизуйтесь повторно для продовження роботи',
  'Roles List': 'Список ролей',
  'Role Item Create': 'Створення ролі',
  'Role Item Update': 'Оновлення ролі',
  'For merch': 'Для мерчанта',
  'Terminals List': 'Список терміналів',
  'Terminals Item Id': 'Інформація про термінал',
  'Terminals Model Create': 'Створення нового терміналу',
  'Terminals Token Update': 'Оновлення ключів',
  'Terminals Item Update': 'Оновлення терміналу',
  'Terminal Item Id': 'Інформація про термінал',
  'Do you want to update token': 'Ви дійсно бажаєте оновити ключ?',
  returnTran: 'Повернення',
  idOperations: 'ID операції',
  reportType: 'Оберіть звіт',
  autoLogout: 'Автовихід через: ',
  'Return to the balance': 'Повернути на баланс',
  'Without changing the balance': 'Без зміни баланса',
  'Register Return': 'Зареєструвати повернення',

  'Merchant List': 'Список мерчантів',
  'Merchant Item Id': 'Опис мерчанта',
  'Merchant Item Update': 'Оновлення мерчанта',
  'Merchant Item Create': 'Створення мерчанта',
  'Merchant Token Update': 'Оновлення ключа мерчанта',
  'Merchant Commissions': 'Комісії',
  'Merchant Commissions External': 'Зовнішня комісія',
  'Merchant Commissions Internal': 'Внутрішня комісія',
  'Merchant Notifications': 'Нотифікації',
  'Merchant Notifications Create': 'Створити',
  'Merchant Notifications Email': 'Почта',
  'Merchant Percentage': 'Процент',
  'Merchant Name': 'Назва мерчанта',
  'Merchant Settings': 'Налаштування мерчанта',

  BalanceLoopBack: 'Зациклення С2А',
  'Create Commissions': 'Створення комісії',
  'Update Commissions': 'Редагування комісії',
  'FinMon Status': 'Статус ФМ',
  'In processing': 'В процесі',
  'Processing allowed': 'Обробка дозволена',
  'Ban on operations': 'Заборона на операції',

  'Contract Number': 'Номер контракту',
  'Contract Name': 'Назва контракту',
  'Contract Date': 'Дата контракту',
  'Act From': 'Act From',
  'Act Till': 'Act Till',

  'Banks List': 'Список банків',
  'Banks Item Id': 'Інформація про банк',
  'Bank Item Create': 'Додавання нового банку',
  'Bank Item Update': 'Оновлення банку',
  'Bank Deposit Update': 'Оновлення ліміту',

  'Transactions Flow List': 'Список схем транзакцій',
  'Transactions Flow Create': 'Створення схеми транзакції',

  'Gateway List': 'Список шлюзів',
  'Gateway Item Create': 'Створення шлюзу',
  'Gateway Item': 'Опис шлюзу',
  'Gateway Item Update': 'Оновлення шлюзу',

  'Cascading Rules List': 'Список правил',
  'Cascading Models List': 'Список моделей',
  'Cascading Model Create': 'Створення моделі',

  'Codes List': 'Список кодів',
  'Codes Item Id': 'Інформація про код',
  'Code Item Update': 'Оновлення коду',
  'Code Item Create': 'Створення коду',
  'Transactions Logs Item': 'Логи транзакцій',
  'Reconciliation List': 'Звірка',
  miss_in_bnk: 'Відсутня у банку',
  miss_in_pt: 'Відсутня у paytech',
  trn_not_registered: 'Не зареєстровано',
  miss_in_bnk2: 'Увага!!!',
  dateStart: 'Період з',
  dateEnd: 'Період до',
  tranTypeId: 'Тип операції',
  bankId: 'Банк',
  respCode: 'Статус',
  amountFrom: 'Сума від',
  amountTo: 'Сума до',
  'card first 6 number': 'Перші 6 цифр картки',
  'card last 4 number': 'Останні 4 цифри картки',
  tranId: 'ID транзакції',
  today: 'За сьогодні',
  yesterday: 'За вчора',
  previousMonth: 'За попередній місяць',
  currentMonth: 'За поточний місяць',

  gateway: 'Банк',
  acsStatus: 'Статус 3DS',
  amount: 'Сума',
  approval: 'Код авторизації',
  callbackUrl: 'Call back URL',
  fee: 'Комісія',
  getewayRefNo: 'ID банку',
  lang: 'Мова',
  pan: 'Картка',
  respCodeId: 'Код відповіді',
  rrn: 'RRN',
  tranType: 'Тип операції',
  description: 'Торгова марка',
  merchant: 'Мерчант',
  status: 'Статус',
  timezone: 'Тимчасова зона',
  comment: 'Коментарі',
  bankName: 'Банк',
  fields: 'Інші поля',
  langEn: 'Англійська',
  langRu: 'Російська',
  langUk: 'Українська',
  external: 'Код',
  merchId: 'Мерчант',
  Profile: 'Профіль',
  Settings: 'Налаштування',
  Logout: 'Вийти',
  Balance: 'Баланс',
  'Own Founds': 'Власні кошти',
  'Accepted Payments': 'Приймаються платежі',
  'Own founds at the begiining of the day': 'Власні знахідки на початку дня',

  'Export List': 'Експорт файлів',
  generated_reports_list: 'Список доступних файлів',
  'City24 Files List': 'Експорт файлов city24',
  'Download File': 'Завантажити файл',
  'Bin List': 'Bin',
  'Invitation link': 'Посилання для запрошення користувачів',

  'Success tab': 'Успешные',
  'Failed tab': 'Отклоненные',
  'Conversion tab': 'Конверсия',

  'Password was successfully changed': 'Пароль был успешно изменен',
  'Reset success send':
    'Инструкция по восстановлению пароля была отправлена на указанный email',
  'Current month': 'Поточний місяць',
  'Last month': 'Минулий місяць',
  'Last 3 month': 'Минулих 3 місяці',
  'Last 6 month': 'Минулих 6 місяців',
  'Beginning of the year': 'З початку року',
  'Get by': 'Отримати за',
  'Available balance': 'Доступний баланс',
  'Own funds': 'Свої кошти',
  Overdraft: 'Овердрафт',
  'Accepted payments': 'Прийняті платежі',
  Payments: 'Видачі',
  'using own funds': 'з власних коштів',
  'using overdraft': 'з овердрафту',
  'using accepted payments': 'з C2A',
  'used overdraft': 'використаний овердрафт',
  terrorists_list: 'Список Терористів',
  sanctions_list: 'Санкційний Список',
  STATUS_NEW: 'NEW',
  STATUS_PROCESSING: 'PROCESSING',
  STATUS_FINISHED: 'FINISHED',
  STATUS_FAILED: 'FAILED',
  userName: 'Користувач',
  fileName: "Ім'я файлу",
  gatewayRefNo: 'Bank-ID',
  'Last Update': 'Останнє Oновлення',
  Update: 'Oновити',
  companyEmail: 'Електронна пошта компанії',
  edrpou: 'ЄДРПОУ',
  Confirmation: 'Ви впевнені, що бажаєте продовжити цю дію?',
  returnBank: 'Повернення Банк',
  'Register payments': 'Реєстрові платежі',
  'Check registry': 'Перевірити реєстр',
  'File processing': 'Обробка файла',
  checkStatusOk: 'Дякую за очікування. Усі карти з файлу валідні',
  'Download file': 'Скачати файл',
  'Invalid cards detected': 'Виявлено недійсні карти',

  accountNumber: 'Номер облікового запису',
  bankMfo: 'MFO',
  mainPhone: 'Основний номер',
  city: 'Місто',
  address: 'Адреса',
  'Companies List': 'Список компаній',
  'Companies Create': 'Створення компанії',
  'Companies Success': 'Компанія успішно створена',
  'Companies Fail': 'Помилка',
  Company: 'Компанія',

  terStatus: 'Статус перевірки по переліку терористів',
  terCheck: 'Дата/Час перевірки по переліку терористів',
  sanStatus: 'Статус перевірки по санкціям РНБО',
  sanCheck: 'Дата/Час перевірки по санкціям РНБО'
};

const fields = {
  sortAscending: 'За зростанням',
  sortDescending: 'По спаданню',
  orderBy: 'Сортувати за',
  uuid: 'ID',
  name: 'Назва',
  resolution: 'Рішення',
  createOn: 'Створено',
  merchant: 'Мерчант',
  tranId: 'ID транзакції',
  tranType: 'Тип операції',
  pan: 'Номер картки',
  amount: 'Сума',
  fee: 'Комісія',
  gateway: 'Еквайр',
  respCode: 'Код відповіді',
  lang: 'Мова',
  editOn: 'Оновлено',
  firstName: "Ім'я",
  lastName: 'Прізвище',
  middleName: 'По-батькові',
  birthDate: 'Дата народження',
  extId: 'Зовнішній ID',
  placeOfBirth: 'Місце народження',
  sanctions_date: 'Дата санкцій',
  fullName: 'Повна назва',
  inn: 'ИНН',
  phone: 'Телефон',
  loginTries: 'Спроб авторизації',
  lastLogin: 'Дата останньої авторизації',
  linkToken: 'Ключ',
  'Two-factor authentication': 'Двофакторна автентифікація',
  role: 'Роль',
  'email table': 'Email',
  field_email: 'Email',
  'role name field': 'Роль',
  'permissions field': 'Дозволи',
  'name bank field': 'Назва банку',
  depositLimit: 'Ліміт депозиту',
  commissions: 'Комісії',
  keyToken: 'Ключі',
  flowName: 'Назва схеми',
  endpoint: 'Точка входу',
  env: 'Оточення',
  bank: 'Банк',
  'name gateway field': 'Назва шлюзу',
  'Select value': 'Вибрати значення',
  notificationChannel: 'Канал нотифікації',
  type_operation: 'Тип операції',
  'Set deposit limit': 'Установить',
  'Increase deposit limit': 'Увеличить',
  'Decrease deposit limit': 'Уменьшить',
  'Comment show': 'Комментарий',
  Loading: 'Загрузка',
  'Upload file': 'Загрузить файл',
  cityRespCode: 'City24 статус',
  'Request success send': 'Запрос успешно отправлен',
  company_email: 'Email компанії',
  company_id: 'ID компанії',
  transaction_count: 'Кількість транзакцій',
  transaction_amount: 'Сума всіх операцій',
  transaction_fee: 'Сума комісії',
  businessName: 'Назва компанії',
  contractNumber: 'Номер договору',
  contractDate: 'Дата договору',
  gateRespCode: 'Код відповіді гейту',
  finMonCheck: 'Статус ФМ',
  inputType: 'Тип платежу',
  frictionless: '3ds Сценарій',
  agreementDate: 'Дата згоди',
  clientName: 'Им`я клієнта',
  sanListCheckStatus: 'Статус перевірки по санкціям РНБО',
  sanListCheckDate: 'Дата/Час перевірки по санкціям РНБО',

  terListCheckStatus: 'Статус перевірки по переліку терористів',
  terListCheckDate: 'Дата/Час перевірки по переліку терористів',
  readiness: 'Готовність',

  enabled: 'Включено',
  disabled: 'Вимкнено',
  disableClick: 'Вимкнути',
  enableClick: 'Увімкнути',
  'New Password': 'Новий пароль',
  'Confirm New Password': 'Підтвердити новий пароль',
  'Change Password': 'Змінити пароль',
  respCodeColor: 'Колір',
  respMessage: 'Текст відповіді',
  gatewayRefNo: 'ID операції гейту',
  receiver: 'Отримувач',
  clear_filters: 'Reset filters',
  account_report: 'Бухгалтерський звіт',
  balancereportprocessor: 'Звіт по балансам марчантів',
  dailytransactionsstatus: 'Звіт по операціям(денний)',
  dovidka: 'Звіт реєстру довідок',
  nbuform1merchantreportprocessor: 'Форма1 НБУ',
  nbuform2merchantreportprocessor: 'Форма2 НБУ до 01.08.2024',
  nbuform3merchantreportprocessor: 'Форма3 НБУ',
  new_default: 'Стандартний звіт(основний)',
  to10nbu: 'Топ-10 ЕПЗ для НБУ',
  merchantdailyreport: 'Стандартний звіт',
  combine_report_no_trx: 'Зведений звіт Б/Т (бухгалтерія)',
  combine_report: 'Зведений звіт, повний (бухгалтерія)',
  decline_codes: 'Коди відмов по операціям',
  finmoncheckresult: 'Звіт Фін-Мон перевірки операцій',
  merchant_balances: 'Баланси мерчанта',
  merchant_fee: 'Комісії мерчанта',
  form2from010824v2reportprocessor: 'Форма2 НБУ з 01.08.2024',
  form5reportprocessor: 'Форма5 НБУ',
  nbuform4reportprocessor: 'Форма4 НБУ',
  over2percard2c: 'Статистика по картам >3 A2C',
  over2percard2a: 'Статистика по картам >2 C2A',
  standartreport: 'Стандартний звіт (обороти)',
  toshortpayaftergiveoutprocessor: 'Передчасне С2A після А2С',
  is3ds: '3D-Secure enabled',
  for_charge: 'Може використовуватись для Charge',
  is_apple_pay: 'Виключно для ApplePay',
  is_google_pay: 'Виключно для GooglePay',
  'Company Signatures': 'Персонал',
  manager: 'ПІБ Директора',
  'Company Fio Signatures': 'ФІО',
  'Company Status Signatures': 'Статус',
  'Company Job Signatures': 'Посада',
  'Company Active Signatures': 'Активний',
  'Company NotActive Signatures': 'Не активний',
  'Create Signatures': 'Створення підписанта',
  'Sunction or Terrorist list': 'Збігів не знайдено',
  'In progress': 'В процесі',
  Verified: 'Перевірено',
  'Found in lists': 'Знайдено у списках',
  CheckedFinMon: 'Перевірено, додаткових рішень не потребує ',
  FinMonTableUser: 'Користувач',
  FinMonTableDate: 'Дата/час',
  FinMonTableComment: 'Коментарій',
  'Block the user': 'Заблокувати користувача ?',
  'Search file': 'Знайти файл',
  'Select Date': 'Вибір дати'
};

const server = {
  'Token error': 'Неправильний ключ',
  '404 error title': 'Сторінку не знайдено',
  '404 error description':
    'Сторінку не знайдено. Швидше за все вона була видалена або переміщена :('
};

const validationForm = {
  required: "Поле обов'язкове для заповнення",
  email: 'Поле має бути поштовою скринькою',
  'Passwords must match': 'ППаролі не співпадають',
  'Error password format':
    'У паролі має бути щонайменше 8 символів. Використовуйте великі та малі літери, а також цифри та спецсимволи',
  'Error phone format': 'Телефон має бути у форматі 380XXXXXXXXX',
  'field float': 'Поле должно быть целым или дробным числом',
  'field number': 'Поле должно быть целым числом'
};

export const ua = {
  'Forgot password': 'Забули пароль?',
  'Create new account': 'Створити акаунт',
  'Login text': 'Авторизація',
  ...buttons,
  ...fields,
  ...text,
  ...validationForm,
  ...menu,
  ...server
};
