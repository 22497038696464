import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

const useActivity = (timeout = 900000) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [countdown, setCountdown] = useState(timeout);
  const navigate = useNavigate();

  useEffect(() => {
    let activityTimeout;
    let countdownInterval;

    const startCountdown = () => {
      setCountdown(timeout);

      countdownInterval = setInterval(() => {
        setCountdown((prev) => {
          const newCountdown = prev - 1000;
          if (newCountdown <= 0) {
            clearInterval(countdownInterval);
            return 0;
          }
          return newCountdown;
        });
      }, 1000);
    };

    activityTimeout = setTimeout(() => {
      setIsModalOpen(true);
    }, timeout);

    const resetActivityTimeout = () => {
      clearTimeout(activityTimeout);
      clearInterval(countdownInterval);
      startCountdown();

      activityTimeout = setTimeout(() => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        navigate('/');
        setIsModalOpen(true);
      }, timeout);
    };

    const events = ['mousemove', 'keydown'];
    events.forEach((event) => {
      window.addEventListener(event, resetActivityTimeout);
    });

    startCountdown();

    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, resetActivityTimeout);
      });
      clearTimeout(activityTimeout);
      clearInterval(countdownInterval);
    };
  }, [timeout]);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return { isModalOpen, handleModalClose, countdown, timeout };
};

export default useActivity;
